<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <h4>Delivery Discount Settings</h4>
      </v-col>

      <v-col cols="6">
        <v-switch
          v-model="applySubsidy"
          label="Apply Delivery Subsidy"
          @change="updateSubsidySetting()"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="!applySubsidy">
      <v-col cols="12" class="my-5">
        <v-icon large color="red">mdi-alert</v-icon>
        <span class="h4">
          Important: Subsiding delivery is very <b>critical</b> to success of
          your business in TakeIn platform. Delivery fee is alway important to
          customers. They compare delivery fee with other delivery apps like
          Uber, etc. Event though total price in TakeIn is less than others.
          TakeIn shows the delivery discount is provided by you to help customer
          to pay less out of pocket
        </span>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        Incentivize larger orders by discounting delivery. You control the
        criteria. E.g. $5 off delivery on orders over $50
      </v-col>
    </v-row>
    <v-container v-if="applySubsidy" class="my-5">
      <v-sheet
        v-for="(item, key) in list"
        :key="key"
        outlined
        rounded
        class="pa-2 ma-2"
      >
        <v-row align="center" class="pa-2 ">
          <v-col lg="1" md="2" sm="3">
            <v-text-field
              v-model="item.from"
              :rules="[rules.required, rules.positived]"
              @input="val => changeFrom(val, key)"
              label="From"
              prefix="$"
              type="number"
              placeholder="50"
            ></v-text-field>
          </v-col>
          <v-col lg="1" md="2" sm="3">
            <v-text-field
              v-model="item.to"
              :rules="[rules.required, rules.positived]"
              @input="val => changeTo(val, key)"
              label="To"
              type="number"
              prefix="$"
              placeholder="45"
            ></v-text-field>
            <!-- , () => {
            console.log(Number(item.from), Number(item.to), 'ppp')
            if(Number(item.from) < Number(item.to)){
              return 'Be less than from';
            }
            return true
          }-->
          </v-col>
          <v-col md="2" sm="3">
            <v-radio-group v-model="item.type" row>
              <v-radio label="%" value="%"></v-radio>
              <v-radio label="$" value="$"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col md="2" sm="3" lg="1">
            <v-text-field
              v-model="item.amount"
              :rules="[rules.required, rules.positived]"
              @input="val => changeAmount(val, key)"
              label="Discount"
              :success="item.amount > 0"
              :prefix="item.type === '$' ? '$' : ''"
              :suffix="item.type === '%' ? '%' : ''"
              placeholder="5"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn icon small @click="removeItem(key)">
              <v-icon color="red">delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
      <v-row class="my-3">
        <v-col>
          <v-btn fab @click="addItem">
            <v-icon small>add</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col>
        <v-btn @click="updateProfileWithSubsidy" color="info">Save</v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" color="orange">
      {{ alertContent }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <pagination-alert
      ref="paginationAlert"
      heading="Warning"
      message="Are you sure you want to leave this page without saving contents?"
    ></pagination-alert>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      alertContent: '',
      list: [],
      snackbar: false,
      applySubsidy: true,
      rules: {
        required: value => !!value || value == 0 || 'Required.',
        positived: value => value >= 0 || 'Be positive!'
      }
    }
  },
  components: {
    PaginationAlert: () =>
      import('fod-core/src/components/common/PaginationAlert.vue')
  },
  computed: {
    ...mapGetters({
      profile: 'profile'
    })
  },
  mounted() {
    if (this.profile && this.profile.settings) {
      this.applySubsidy = this.profile.settings.delivery_subsidy
    }
    this.$store
      .dispatch('getUserSetting', { type: 'delivery_subsidy' })
      .then(settings => {
        if (settings && settings.delivery_subsidy) {
          this.list = settings.delivery_subsidy
          // this.applySubsidy = settings.opt_out ? false : true
        } else {
          this.$store.dispatch('loadDefaultDeliverySubsidy').then(data => {
            this.list = data.default_delivery_subsidy
          })
        }
      })
  },
  // beforeRouteLeave(to, from, next) {
  // this.$refs.paginationAlert.openDialog()
  // this.$nextTick(() => {
  //   this.$refs.paginationAlert.$once('onYes', () => {
  //     next(true)
  //   })
  //   this.$refs.paginationAlert.$once('onCancel', () => {
  //     next(false)
  //   })
  // })
  // },
  methods: {
    canSave() {
      let can = true
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].from < 0) {
          can = false
          this.alertContent = '"From" value must be larger than 0.'
          this.snackbar = true
          break
        }
        if (this.list[i].to <= 0 || this.list[i].to <= this.list[i].from) {
          can = false
          this.alertContent = '"To" value must be larger than "From".'
          this.snackbar = true
          break
        }
        if (this.list[i].amount <= 0) {
          can = false
          this.alertContent = '"Amount" value must be larger than 0.'
          this.snackbar = true
        }
      }
      return can
    },
    updateProfileWithSubsidy() {
      if (this.canSave()) {
        // this.profile.settings.delivery_subsidy = this.list
        this.$store.dispatch('saveSubSetting', {
          type: 'delivery_subsidy',
          data: this.list
        })
      } else {
        this.$store.dispatch('saveSubSetting', {
          type: 'delivery_subsidy',
          data: []
        })
      }
    },
    addItem() {
      this.list.push({ from: 0, to: 0, amount: 0.0 })
    },
    removeItem(key) {
      this.list = this.list.filter((_, i) => i !== key)
    },
    changeFrom(value, key) {
      this.list[key].from = Number(value)
      /*  if (value < 50) {
        this.list[key].amount = 0
      } else if (value >= 50 && value < 100) {
        this.list[key].amount = 5
      } else if (value >= 100 && value < 150) {
        this.list[key].amount = 10
      } else {
        this.list[key].amount = 15
      }
      this.list[key].to = value - this.list[key].amount */
    },
    changeTo(value, key) {
      this.list[key].to = Number(value)
      //this.list[key].amount = this.list[key].from - value
    },
    changeAmount(value, key) {
      this.list[key].amount = Number(value)
      //this.list[key].to = this.list[key].from - value
    },
    updateSubsidySetting() {
      this.$store.dispatch('updateSubsidySetting', {
        delivery_subsidy: this.applySubsidy
      })
    }
  }
}
</script>
